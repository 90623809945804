.display_flex {
    display: flex !important;
    height: 100% !important
}

.main_content {
    transition: width 0.6s ease;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}

// .main_content::-webkit-scrollbar {
//     display: none !important;
// }

.header_logo {
    max-width: 120px !important;
    margin-left: 15px !important;
    cursor: pointer !important;
}

.left_menu {
    flex-grow: 1 !important;
    background-color: white !important; 
    transition: .5s !important; 
    overflow: auto !important; 
    scrollbar-width: none !important;
}

.left_menu::-webkit-scrollbar {
    display: none !important;
}   

.dashboard_add_btn_container {
    position: absolute !important; 
    left: 16px !important; 
    display: flex !important;
    align-items: center !important
}

.dashboard_btn_label_text {
    font-size: 16px !important;
    font-weight: 600  !important;
    height: 70px  !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    & .addIcon {
        font-size: 25px;
        visibility: hidden;
        position: absolute;
        top: 8px;
        right: 8px;
    }
    &:hover {
        color: #fff;
        & .addIcon {
            visibility: visible;
        }
    }
}

.circular_loading {
    position: relative !important;
    left: 50% !important;
    right:50% !important;
    margin-top: 3% !important;
}

.content_container {
    margin-top: 8px !important;
    margin-bottom: 24px !important;
}

.table_sticky_top {
    position: sticky !important;
    top: 0px !important;
    z-index: 99 !important;
    background: #f7f7f7 !important;
}

.table_action_icon {
    font-size: 18px !important;
}

.list_button_container {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    right: 44px;
}

@media (max-width: 1950px) {
    .list_button_container {
        right: 42px;
    }
}

@media (max-width: 1450px) {
    .list_button_container {
        right: 41px;
    }
}

.see_invoice_btn {
    color:#5b51bf !important;
    cursor: pointer !important;
    margin-left: 3px !important;
}

.see_invoice_btn:hover {
    color: #738bed !important;
}

.flat_icons {
    font-size: 15px !important; 
    margin-right: 6px !important;
    display: flex !important;
    align-items: center !important;
}

.attach_icon {
    margin-left: 6px !important;
    font-size: 0.8rem !important;
    height: 20px !important;
    color: white !important;
}

.table_icons {
    color: black !important;
    font-size: 15px !important;
    min-width: 20px !important;
    line-height: 10px !important;
}

.entity_list_logo {
    width:100% !important;
    object-fit: cover !important;
    object-position: center !important;
}

.settings_content_container {
    width:100% !important;
    background: #fff !important;
    border-radius: 4px !important;
    margin-bottom: 32px !important;
}

.custom_margin_top {
    margin-top: 16px !important; 
}

.custom_margin_bottom {
    margin-bottom: -8px !important;
}

.signature_style {
    position: absolute;
    bottom: 68px;
    left: 2px;
    width: 200px;
    height: 70px;
    box-shadow: 0 0 4px 2px gainsboro;
    padding: 10px;
    object-fit: contain;
}

.bank_action_icon {
    color: #b5bbc2 !important;
    font-size: 18px !important;
    margin-right: 8px !important;
}

.drawer_signature_style {
    border: 1px solid rgba(0,0,0,0.1) !important;
    padding: 8px !important;
    width: 85px !important;
    height: 45px !important;
    border-radius: 5px !important;
    max-width: 300px !important;
}

.free_plan_list_container {
    margin-top: 32px !important;
    list-style: none !important;
    padding: 0 !important;
}

.billing_list_item_style {
    font-size: 19px !important;
    margin: 6px 0 !important;
}

.divider_margin {
    margin: 0px !important;
}

.component_container {
    overflow: auto !important;
    overflow-x: hidden !important;
    scrollbar-width: none !important;
}

.component_container::-webkit-scrollbar {
    display: none !important;
}

.amount_column {
    width: 195px !important;
    max-width: 195px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

@media (max-width: 1400px) {
    .amount_column {
        width: 145px !important;
        max-width: 145px !important;
    }
}

@media (max-width: 1366px) {
    .amount_column {
        width: 115px !important;
        max-width: 115px !important;
    }
}

@media (max-width: 1360px) {
    .amount_column {
        width: 115px !important;
        max-width: 115px !important;
    }
}

@media (max-width: 1280px) {
    .amount_column {
        width: 105px !important;
        max-width: 105px !important;
    }
}

.modal_back_icon {
    width: 35px !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    margin-left: 20px !important;
    font-size: 21px !important;
} 